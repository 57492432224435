<template>
  <div class="app">
    <keep-alive>
       <router-view v-if="$route.meta.isAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.isAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '@/styles/index.scss';
  .app{
  }
</style>
