/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-10 13:55:42
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import basicRouters from './basicRouter'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
console.log('异步路由', basicRouters)
const routes = basicRouters
const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
