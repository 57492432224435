/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 10:20:32
 * @LastEditTime: 2022-08-05 16:11:47
 */
import storage from 'store'
import {
	permissionListToPermissions
} from '@/utils/permissionsFormat'
import {
	authLogin,
	getUserPermission,
	getUserInfo,
} from "@/api/login"
const user = {
	state: {
		token: '',
		name: '',
		roles: []
	},
	mutations: {
		SET_TOKEN: (state, intToken) => {
			state.token = intToken
			storage.set('ACCESS_TOKEN', intToken, 7 * 24 * 60 * 60 * 1000)
		},
		SET_ROLES: (state, intRoles) => {
			state.roles = intRoles
			storage.set('ACCESS_ROLES', intRoles, 7 * 24 * 60 * 60 * 1000)
		},
		SET_NAME: (state, intName) => {
			state.name = intName
			storage.set('ACCESS_Name', intName, 7 * 24 * 60 * 60 * 1000)
		},
		SET_INFO: (state, intInfo) => {
			storage.set('ACCESS_Info', intInfo, 7 * 24 * 60 * 60 * 1000)
		},
	},
	actions: {
		Login({
			commit
		}, intParams) {
			return new Promise((resolve, reject) => {
				authLogin(intParams).then((res) => {
					// console.log("登录信息", res)
					if (res.code == 0) {
						commit('SET_NAME', intParams.username)
						commit('SET_TOKEN', res.data)
					}
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		GetUserPerm({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getUserPermission().then((res) => {
					const permissionData = res.data.role.permissionList
					const permissionArr = []
					permissionData.forEach((item) => {
						permissionArr.push({
							permissionId: item.resourceCode,
							permissionName: item.displayName
						})
					})
					console.log('getUserPermission>>>>', permissionArr)
					const response = {
						result: {
							role: {
								permissions: permissionArr
							}
						}
					}
					const result = response.result
					if (result.role && result.role.permissions.length > 0) {
						const role = result.role
						role.permissionList = role.permissions.map(per => {
							return per.permissionId
						})
						commit('SET_ROLES', result.role)
					}
					resolve(response)
				})
			})
		},
		GetUserInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getUserInfo().then((res) => {
					// console.log("用户信息", res.data)
					commit('SET_INFO', res.data)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		Logout({
			commit
		}) {
			commit('SET_TOKEN', '')
			commit('SET_NAME', '')
			commit('SET_ROLES', [])
			commit('SET_INFO', {})
			storage.remove("historyRoute")
			sessionStorage.removeItem("routerArr")
		}
	}
}
export default user
