<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="common-title">
		{{title}}
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {};
		}
	};
</script>

<style lang="scss" scoped>
	.common-title {
		line-height: 22px;
		padding-left: 16px;
		box-sizing: border-box;
		border-left: 4px solid $theme_color;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #1CABB9;
		margin: 10px 0;
	}
</style>
