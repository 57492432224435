/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-27 18:15:12
 * @LastEditTime: 2022-03-24 13:43:43
 */
import axios from 'axios'
import storage from "store";
import store from '@/store'
import Vue from 'vue'
import {
	Message,
	Notification
} from 'element-ui'
import {
	VueAxios
} from './axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const request = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 100000
})

// 异常拦截处理器
const errorHandler = (error) => {
	if (error.response) {
		const data = error.response.data
		// console.log("异常拦截处理器", data)
		const token = storage.get('ACCESS_TOKEN')
		if (data.code == '4001') {
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		} else {
			let errorMsg = ""
			if (data.msg.indexOf('操作执行失败: ') == -1) {
				errorMsg = data.msg
			} else {
				errorMsg = data.msg.replace("操作执行失败: ", "")
			}
			Message({
				message: errorMsg,
				type: 'error'
			})
			// Notification({
			// 	type: 'error',
			// 	message: data.msg,
			// 	title: '错误'
			// })
		}
	}
	// setTimeout(() => {
	// 	Vue.$loading.hide()
	// }, 1000)
	return Promise.reject(error)
}

// 请求拦截器
request.interceptors.request.use(config => {
	// Vue.$loading.show()
	const token = storage.get('ACCESS_TOKEN')
	if (token) {
		// console.log("登录token", token)
		//测试
		// config.headers['x-zjut-volunteer-auth-token-dev'] = token
		//生产
		config.headers['x-zjut-volunteer-auth-token'] = token
	}
	return config
}, errorHandler)


// 响应拦截器
request.interceptors.response.use(res => {
	// setTimeout(() => {
	// 	Vue.$loading.hide()
	// }, 1000)
	// console.log("响应拦截器", res)
	const code = res.data.code
	if (code && code != '0') {
		const token = storage.get('ACCESS_TOKEN')
		if (code == '4001') {
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		} else {
			let errorMsg = ""
			if (res.data.msg.indexOf('操作执行失败: ') == -1) {
				errorMsg = res.data.msg
			} else {
				errorMsg = res.data.msg.replace("操作执行失败: ", "")
			}
			Message({
				message: errorMsg,
				type: 'error'
			})
			// Notification({
			// 	type: 'error',
			// 	message: res.data.msg,
			// 	title: '错误'
			// })
		}
	}
	return res.data
}, errorHandler)
const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request)
	}
}

export default request

export {
	installer as VueAxios,
	request as axios
}
