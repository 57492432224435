/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 09:48:11
 * @LastEditTime: 2021-06-10 16:44:19
 */
import asyncRouters from '@/router/asyncRouter'
import basicRouters from '@/router/basicRouter'
function hasPermission(permission, route) {
    if (route.meta && route.meta.permission) {
        let flag = false
        for (let i = 0, len = permission.length; i < len; i++) {
            flag = route.meta.permission.includes(permission[i])
            if (flag) {
                return true
            }
        }
        return false
    }
    return true
}
function filterAsyncRouter(routerMap, roles) {
    // console.log('路由过滤1', routerMap, roles)
    const accessedRouters = routerMap.filter(route => {
        // console.log('路由过滤2', route)
        if (hasPermission(roles.permissionList, route)) {
            // console.log('路由过滤3')
            if (route.children && route.children.length) {
                // console.log('路由过滤4')
                route.children = filterAsyncRouter(route.children, roles)
            }
            return true
        }
        return false
    })
    // console.log('路由过滤5', accessedRouters)
    return accessedRouters
}
const permission = {
    state: {
        routers: basicRouters,
        addRouters: []
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            // console.log('routers', routers)
            state.routers = basicRouters.concat(routers)
        }
    },
    actions: {
        GenerateRoutes({ commit }, data) {
            return new Promise(resolve => {
                const { roles } = data
                // console.log('accessedRouters', asyncRouters)
                const accessedRouters = filterAsyncRouter(asyncRouters, roles)
                commit('SET_ROUTERS', accessedRouters)
                resolve()
            })
        }
    }
}

export default permission
