/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 11:23:15
 * @LastEditTime: 2022-08-05 16:02:42
 */
import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // 浏览器加载进度条
import {
	setDocumentTitle,
	domTitle
} from '@/utils/domUtil'
import {
	i18nRender
} from '@/locales'
NProgress.configure({
	showSpinner: false
})
const allowList = ['login', 'PrivacyAgreement', 'useAgreement']
const loginRoutePath = '/login'
const defaultRoutePath = '/recruitList'

router.beforeEach((to, from, next) => {
	console.log('路由守卫', to)
	NProgress.start()
	to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(
		`${domTitle} - ${i18nRender(to.meta.title)}`))
	// token已存在
	if (storage.get('ACCESS_TOKEN')) {
		// console.log('有token')
		if (to.path === loginRoutePath) {
			// console.log('有token-login')
			next({
				path: defaultRoutePath
			})
			NProgress.done()
		} else {
			// console.log('有token-不是login1', store.getters.roles)
			if (store.getters.roles.length === 0) {
				store.dispatch('GetUserPerm').then((res) => {
					// console.log('有token-不是login2', res)
					const roles = res.result && res.result.role
					// console.log('有token-不是login3', roles)
					store.dispatch('GenerateRoutes', {
						roles
					}).then(() => {
						// 根据roles权限生成可访问的路由表
						// 动态添加可访问路由表
						router.addRoutes(store.getters.addRouters)
						const redirect = decodeURIComponent(from.query.redirect || to.path)
						if(to.path === redirect){
							next({ ...to, replace: true })
						}else{
							next({ path: redirect })
						}
					})
				}).catch(() => {
					// 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
					store.dispatch('Logout').then(() => {
						next({
							path: loginRoutePath,
							query: {
								redirect: to.fullPath
							}
						})
					})
				})
			} else {
				if(to.path == '/404'){
					let finalRouters = store.getters.addRouters[0].children
					let hasChildren= finalRouters.find(item => item.children&&item.children.length > 0)
					let finalPath = hasChildren.children[0].path
					console.log(finalPath)
					next({ path: finalPath })
				}else{
					next()
				}
				// next()
			}
		}
	} else {
		// console.log('无token')
		if (allowList.includes(to.name)) {
			// 在免登录名单，直接进入
			next()
		} else {
			next({
				path: loginRoutePath,
				// query: {
				// 	redirect: to.fullPath
				// }
			})
			NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
		}
	}
})
router.afterEach(() => {
	NProgress.done() // finish progress bar
})
