/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 17:32:06
 * @LastEditTime: 2021-06-10 17:33:28
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
    silentTranslationWarn: true,
    locale: 'en-US',
    fallbackLocale: 'en-US'
})
export function i18nRender(key) {
    return i18n.t(`${key}`)
}
