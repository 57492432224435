import axios from '@/utils/request'
let Api = {
	login: "/auth/login",
	userPermission: "/auth/userInfo",
	userInfo: "/iam/user/getCurrentUserInfo",
}
// 登录
export function authLogin(data) {
	return axios({
		method: 'post',
		url: Api.login,
		data: data
	})
}
// 用户权限
export function getUserPermission(data) {
	return axios({
		method: 'get',
		url: Api.userPermission,
		params: data
	})
}
// 用户信息
export function getUserInfo(data) {
	return axios({
		method: 'get',
		url: Api.userInfo,
		params: data
	})
}