/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-06-17 16:54:28
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式统一文件
import 'normalize.css/normalize.css'
import '@/styles/index.scss'
// 引入自定义指令
import globalPul from "./utils/directive.js"
globalPul.install(Vue)
// element组件(按需引入)
// import './libs'
// 全部引入element组件
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// 引入菜单权限
import './permission'
// 引入按钮权限
import btnPermission from '@/directive/btnPermission/index'
btnPermission.install(Vue)
// 全局图标
import './icons'
// 引入iconfont
import './assets/icon/iconfont.css'
// 引入animate
import animated from 'animate.css'
Vue.use(animated)
// 引入富文本编辑框
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 引入全局自定义组件
import commonTitle from './components/commonTitle.vue'
Vue.component('common-title', commonTitle)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
