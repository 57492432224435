/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-08-25 11:13:18
 */
const RouteView = {
    name: 'RouteView',
    render: (h) => h('router-view')
}
const asyncRouters = [{
        path: '/',
        name: '',
        component: () => import('@/layout'),
        meta: {
            title: '志愿服务系统'
        },
        redirect: '/recruitList',
        children: [{
                path: 'recruitManage',
                name: 'RecruitManage',
                component: RouteView,
                meta: {
                    title: '招募管理',
                    permission: ['recruit'],
                    icon: "icon-a-zu17070"
                },
                children: [{
                        path: '/recruitList',
                        name: 'RecruitList',
                        component: () => import('@/views/recruitManage/recruitList/index'),
                        meta: {
                            title: '招募列表',
                            permission: ['recruitList'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/recruitList/detail',
                        name: 'RecruitDetail',
                        component: () => import('@/views/recruitManage/recruitList/detail'),
                        meta: {
                            title: '详情',
                            permission: ['recruitList'],
                            parentRoute: [{
                                title: "招募列表",
                                path: "/recruitList"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/recruitList/edit',
                        name: 'RecruitEdit',
                        component: () => import('@/views/recruitManage/recruitList/edit'),
                        meta: {
                            title: '修改招募',
                            permission: ['recruitList'],
                            parentRoute:[
                                {
                                    title: "招募列表",
                                    path: "/recruitList"
                                }
                            ]
                        },
                        hidden: true
                    },
                    {
                        path: '/enrollResult',
                        name: 'EnrollResult',
                        component: () => import('@/views/recruitManage/enrollResult'),
                        meta: {
                            title: '录取结果通知',
                            permission: ['recruitList'],
                            parentRoute: [{
                                title: "详情",
                                path: "/recruitList/detail"
                            }, {
                                title: "招募列表",
                                path: "/recruitList"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/timeSheet',
                        name: 'TimeSheet',
                        component: () => import('@/views/recruitManage/timeSheet'),
                        meta: {
                            title: '生成工时表',
                            permission: ['recruitList'],
                            parentRoute: [{
                                title: "详情",
                                path: "/recruitList/detail"
                            }, {
                                title: "招募列表",
                                path: "/recruitList"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/recruitAdd',
                        name: 'RecruitAdd',
                        component: () => import('@/views/recruitManage/recruitAdd'),
                        meta: {
                            title: '新建招募',
                            permission: ['recruitCreate']
                        },
                    },
                    {
                        path: '/recruitSearch',
                        name: 'RecruitSearch',
                        component: () => import('@/views/recruitManage/recruitSearch'),
                        meta: {
                            title: '招募查询',
                            permission: ['recruitQuery']
                        },
                    }
                ]
            },
            {
                path: 'activityManage',
                name: 'ActivityManage',
                component: RouteView,
                meta: {
                    title: '活动管理',
                    permission: ['activity'],
                    icon: "icon-a-lujing41101"
                },
                children: [{
                        path: '/activityList',
                        name: 'ActivityList',
                        component: () => import('@/views/activityManage/activityList/index'),
                        meta: {
                            title: '活动列表',
                            permission: ['activityList'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/activityList/detail',
                        name: 'ActivityAdd',
                        component: () => import('@/views/activityManage/activityList/detail'),
                        meta: {
                            title: '活动详情',
                            permission: ['activityList'],
                            parentRoute: [{
                                title: "活动列表",
                                path: "/activityList"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityList/edit',
                        name: 'ActivityEdit',
                        component: () => import('@/views/activityManage/activityList/edit'),
                        meta: {
                            title: '活动编辑',
                            permission: ['activityList'],
                            parentRoute: [{
                                title: "活动列表",
                                path: "/activityList"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activitySign',
                        name: 'ActivitySign',
                        component: () => import('@/views/activityManage/activitySign'),
                        meta: {
                            title: '活动签到',
                            permission: ['activitySign'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/activitySign/detail',
                        name: 'ActivityDetail',
                        component: () => import('@/views/activityManage/activitySign/detail'),
                        meta: {
                            title: '详情',
                            permission: ['activitySign'],
                            parentRoute: [{
                                title: "活动签到",
                                path: "/activitySign"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityAdd',
                        name: 'ActivityAdd',
                        component: () => import('@/views/activityManage/activityAdd'),
                        meta: {
                            title: '新建一般活动',
                            permission: ['activityCreateNormal']
                        },
                    },
                    {
                        path: '/activitySearch',
                        name: 'ActivitySearch',
                        component: () => import('@/views/activityManage/activitySearch'),
                        meta: {
                            title: '活动查询',
                            permission: ['activityQuery']
                        },
                    },
                    {
                        path: '/activityMy',
                        name: 'ActivityMy',
                        component: () => import('@/views/activityManage/activityMy/index'),
                        meta: {
                            title: '我的共建基地及活动',
                            permission: ['activityAndBaseBuild']
                        },
                    },
                    {
                        path: '/activityMy/detail',
                        name: 'ActivityMyDetail',
                        component: () => import('@/views/activityManage/activityMy/detail'),
                        meta: {
                            title: '详情',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityMy/addBase',
                        name: 'ActivityMyAddBase',
                        component: () => import('@/views/activityManage/activityMy/addBase'),
                        meta: {
                            title: '新建共建基地',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityMy/editBase',
                        name: 'ActivityMyEditBase',
                        component: () => import('@/views/activityManage/activityMy/editBase'),
                        meta: {
                            title: '编辑共建基地',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityMy/addAc',
                        name: 'ActivityMyaddAc',
                        component: () => import('@/views/activityManage/activityMy/addAc'),
                        meta: {
                            title: '新建共建基地活动',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy",
                                isAlive:true
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityMy/editAc',
                        name: 'ActivityMyeditAc',
                        component: () => import('@/views/activityManage/activityMy/editAc'),
                        meta: {
                            title: '编辑共建基地活动',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityMy/baseDetail',
                        name: 'ActivityMybaseDetail',
                        component: () => import('@/views/activityManage/activityMy/baseDetail'),
                        meta: {
                            title: '共建基地详情',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityMy/acDetail',
                        name: 'ActivityMyAcDetail',
                        component: () => import('@/views/activityManage/activityMy/acDetail'),
                        meta: {
                            title: '共建基地活动详情',
                            permission: ['activityAndBaseBuild'],
                            parentRoute: [{
                                title: "我的共建基地及活动",
                                path: "/activityMy"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/activityAll',
                        name: 'ActivityAll',
                        component: () => import('@/views/activityManage/activityAll'),
                        meta: {
                            title: '我的全部一般活动',
                            permission: ['activityQueryNormal'],
                            isAlive:true
                        },
                    }
                ]
            },
            {
                path: 'activityHighManage',
                name: 'ActivityHighManage',
                component: RouteView,
                meta: {
                    title: '活动高级管理',
                    permission: ['activityManage'],
                    icon: "icon-a-zu19067"
                },
                children: [{
                        path: '/activityVerify',
                        name: 'ActivityVerify',
                        component: () => import('@/views/activityHighManage/activityVerify/index'),
                        meta: {
                            title: '一般活动审核',
                            permission: ['activityVerify'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/activityVerify/detail',
                        name: 'ActivityVerifyDetail',
                        component: () => import('@/views/activityHighManage/activityVerify/detail'),
                        meta: {
                            title: '详情',
                            permission: ['activityVerify'],
                            parentRoute: [{
                                title: "一般活动审核",
                                path: "/activityVerify"
                            }]
                        },
                        hidden: true
                    }
                ]
            },
            {
                path: 'baseHighManage',
                name: 'BaseHighManage',
                component: RouteView,
                meta: {
                    title: '共建基地高级管理',
                    permission: ['baseBuild'],
                    icon: "icon-a-zu19116"
                },
                children: [{
                        path: '/baseVerify',
                        name: 'BaseVerify',
                        component: () => import('@/views/baseHighManage/baseVerify/index'),
                        meta: {
                            title: '共建基地审核',
                            permission: ['baseBuildVerify'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/baseVerify/verify',
                        name: 'BaseVerify',
                        component: () => import('@/views/baseHighManage/baseVerify/verify'),
                        meta: {
                            title: '审核',
                            permission: ['baseBuildVerify'],
                            parentRoute: [{
                                title: "共建基地审核",
                                path: "/baseVerify"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/baseDelete',
                        name: 'BaseDelete',
                        component: () => import('@/views/baseHighManage/baseDelete'),
                        meta: {
                            title: '共建基地删除',
                            permission: ['baseBuildDelete']
                        },
                    },
                    {
                        path: '/baseActivityVerify',
                        name: 'BaseActivityVerify',
                        component: () => import('@/views/baseHighManage/baseActivityVerify/index'),
                        meta: {
                            title: '共建基地活动审核',
                            permission: ['baseBuildActivityVerify'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/baseActivityVerify/verify',
                        name: 'BaseActivityVerifyDetail',
                        component: () => import('@/views/baseHighManage/baseActivityVerify/verify'),
                        meta: {
                            title: '审核',
                            permission: ['baseBuildActivityVerify'],
                            parentRoute: [{
                                title: "共建基地活动审核",
                                path: "/baseActivityVerify"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/baseActivitySurvey',
                        name: 'BaseActivitySurvey',
                        component: () => import('@/views/baseHighManage/baseActivitySurvey/index'),
                        meta: {
                            title: '共建基地活动概况',
                            permission: ['baseBuildOverview'],
                            isAlive:true
                        },
                    }
                ]
            },
            {
                path: 'manHourManage',
                name: 'ManHourManage',
                component: RouteView,
                meta: {
                    title: '工时管理',
                    permission: ['manHour'],
                    icon: "icon-a-lujing41102"
                },
                children: [{
                        path: '/studentManHour',
                        name: 'StudentManHour',
                        component: () => import('@/views/manHourManage/studentManHour/index'),
                        meta: {
                            title: '学生工时',
                            permission: ['studentManHour'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/studentManHour/detail',
                        name: 'StudentManHourDetail',
                        component: () => import('@/views/manHourManage/studentManHour/detail'),
                        meta: {
                            title: '详情',
                            permission: ['studentManHour'],
                            parentRoute: [{
                                title: "学生工时",
                                path: "/studentManHour"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/manHourFill',
                        name: 'ManHourFill',
                        component: () => import('@/views/manHourManage/manHourFill/index'),
                        meta: {
                            title: '工时补传',
                            permission: ['manHourUpload'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/manHourFill/detail',
                        name: 'StudentManHourDetail',
                        component: () => import('@/views/manHourManage/manHourFill/detail'),
                        meta: {
                            title: '详情',
                            permission: ['manHourUpload'],
                            parentRoute: [{
                                title: "工时补传",
                                path: "/manHourFill"
                            }]
                        },
                        hidden: true
                    },
                    // {
                    // 	path: '/manHourVerify',
                    // 	name: 'ManHourVerify',
                    // 	component: () => import('@/views/manHourManage/manHourVerify/index'),
                    // 	meta: {
                    // 		title: '工时审核',
                    // 		permission: ['manHourVerify']
                    // 	},
                    // },
                    // {
                    //     path: '/manHourVerify/verify',
                    //     name: 'ManHourVerifyDetail',
                    //     component: () => import('@/views/manHourManage/manHourVerify/verify'),
                    //     meta: {
                    //         title: '详情',
                    //         permission: ['manHourVerify'],
                    //         parentRoute: [{
                    //             title: "工时审核",
                    //             path: "/manHourVerify"
                    //         }]
                    //     },
                    //     hidden: true
                    // },
                    {
                        path: '/manHourDelete',
                        name: 'ManHourDelete',
                        component: () => import('@/views/manHourManage/manHourDelete/index'),
                        meta: {
                            title: '删除工时',
                            permission: ['manHourDelete'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/manHourDelete/detail',
                        name: 'ManHourDeleteDetail',
                        component: () => import('@/views/manHourManage/manHourDelete/detail'),
                        meta: {
                            title: '详情',
                            permission: ['manHourDelete'],
                            parentRoute: [{
                                title: "删除工时",
                                path: "/manHourDelete"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/upLoadAisle',
                        name: 'UpLoadAisle',
                        component: () => import('@/views/manHourManage/upLoadAisle'),
                        meta: {
                            title: '开启上传通道',
                            permission: ['openManHourUpload']
                        },
                    }
                ]
            },
            {
                path: 'electronicManHours',
                name: 'ElectronicManHours',
                component: RouteView,
                meta: {
                    title: '电子工时证明',
                    permission: ['manHourProve'],
                    icon: "icon-a-zu19066"
                },
                children: [{
                        path: '/hearApply',
                        name: 'HearApply',
                        component: () => import('@/views/electronicManHours/hearApply/index'),
                        meta: {
                            title: '审理申请',
                            permission: ['manHourVerify'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/hearApply/detail',
                        name: 'HearApplyDetail',
                        component: () => import('@/views/electronicManHours/hearApply/detail'),
                        meta: {
                            title: '详情',
                            permission: ['manHourVerify'],
                            parentRoute: [{
                                title: "审理申请",
                                path: "/hearApply"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/issueHistory',
                        name: 'IssueHistory',
                        component: () => import('@/views/electronicManHours/issueHistory/index'),
                        meta: {
                            title: '开具记录',
                            permission: ['mahHourRecord'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/issueHistory/detail',
                        name: 'IssueHistoryDetail',
                        component: () => import('@/views/electronicManHours/issueHistory/detail'),
                        meta: {
                            title: '详情',
                            permission: ['mahHourRecord'],
                            parentRoute: [{
                                title: "开具记录",
                                path: "/issueHistory"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/manHoursProve',
                        name: 'ManHoursProve',
                        component: () => import('@/views/electronicManHours/manHoursProve'),
                        meta: {
                            title: '工时证明文件',
                            permission: ['manHourProveFile']
                        },
                    },
                ]
            },
            {
                path: 'volunteerVote',
                name: 'VolunteerVote',
                component: RouteView,
                meta: {
                    title: '志愿者评选',
                    permission: ['volunteerSelection'],
                    icon: "icon-a-zu17071"
                },
                children: [{
                        path: '/volunteerList',
                        name: 'VolunteerList',
                        component: () => import('@/views/volunteerVote/volunteerList'),
                        meta: {
                            title: '志愿者列表',
                            permission: ['volunteerList']
                        },
                    },
                    {
                        path: '/voteSet',
                        name: 'VoteSet',
                        component: () => import('@/views/volunteerVote/voteSet/index'),
                        meta: {
                            title: '评选设置',
                            permission: ['volunteerSetting'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/voteSet/detail',
                        name: 'VoteSetDetail',
                        component: () => import('@/views/volunteerVote/voteSet/detail'),
                        meta: {
                            title: '详情',
                            permission: ['volunteerSetting'],
                            parentRoute: [{
                                title: "评选设置",
                                path: "/voteSet"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/starVolunteer',
                        name: 'StarVolunteer',
                        component: () => import('@/views/volunteerVote/starVolunteer/index'),
                        meta: {
                            title: '星级志愿者',
                            permission: ['volunteerStar'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/starVolunteer/detail',
                        name: 'StarVolunteerDetail',
                        component: () => import('@/views/volunteerVote/starVolunteer/detail'),
                        meta: {
                            title: '详情',
                            permission: ['volunteerStar'],
                            parentRoute: [{
                                title: "星级志愿者",
                                path: "/starVolunteer"
                            }]
                        },
                        hidden: true
                    },
                    // {
                    //     path: '/registChange',
                    //     name: 'RegistChange',
                    //     component: () => import('@/views/volunteerVote/registChange'),
                    //     meta: {
                    //         title: '注册号更改',
                    //         permission: ['volunteerChange']
                    //     },
                    // },
                    {
                        path: '/registBind',
                        name: 'RegistBind',
                        component: () => import('@/views/volunteerVote/registBind'),
                        meta: {
                            title: '注册号更改',
                            permission: ['volunteerChange']
                        },
                    },
                ]
            },
            {
                path: 'materialsCollect',
                name: 'MaterialsCollect',
                component: RouteView,
                meta: {
                    title: '材料收集',
                    permission: ['material'],
                    icon: "icon-a-zu18932"
                },
                children: [{
                        path: '/createMaterials',
                        name: 'CreateMaterials',
                        component: () => import('@/views/materialsCollect/createMaterials'),
                        meta: {
                            title: '新建材料收集',
                            permission: ['materialCreate']
                        },
                    },
                    {
                        path: '/allMaterialsCollect',
                        name: 'AllMaterialsCollect',
                        component: () => import('@/views/materialsCollect/allMaterialsCollect/index'),
                        meta: {
                            title: '全部材料收集',
                            permission: ['materialAll'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/allMaterialsCollect/detail',
                        name: 'AllMaterialsCollectDetail',
                        component: () => import('@/views/materialsCollect/allMaterialsCollect/detail'),
                        meta: {
                            title: '详情',
                            permission: ['materialAll'],
                            parentRoute: [{
                                title: "全部材料收集",
                                path: "/allMaterialsCollect"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/allMaterialsManage',
                        name: 'AllMaterialsManage',
                        component: () => import('@/views/materialsCollect/allMaterialsManage/index'),
                        meta: {
                            title: '全部材料管理',
                            permission: ['materialManage'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/allMaterialsManage/detail',
                        name: 'AllMaterialsCollectDetail',
                        component: () => import('@/views/materialsCollect/allMaterialsManage/detail'),
                        meta: {
                            title: '详情',
                            permission: ['materialManage'],
                            parentRoute: [{
                                title: "全部材料管理",
                                path: "/allMaterialsManage"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/allMaterialsManage/edit',
                        name: 'AllMaterialsCollectEdit',
                        component: () => import('@/views/materialsCollect/allMaterialsManage/edit'),
                        meta: {
                            title: '编辑',
                            permission: ['materialManage'],
                            parentRoute: [{
                                title: "全部材料管理",
                                path: "/allMaterialsManage"
                            }]
                        },
                        hidden: true
                    },
                ]
            },
            {
                path: 'studentViews',
                name: 'StudentViews',
                component: RouteView,
                meta: {
                    title: '学生信息',
                    permission: ['studentInfo'],
                    icon: "icon-a-zu19187"
                },
                children: [
                    // 	{
                    // 	path: '/studentViewsVerify',
                    // 	name: 'StudentViewsVerify',
                    // 	component: () => import('@/views/studentViews/studentViewsVerify/index'),
                    // 	meta: {
                    // 		title: '学生信息审核',
                    // 		permission: ['studentInfoVerify']
                    // 	},
                    // }, 
                    {

                        path: '/recordsAppeal',
                        name: 'RecordsAppeal',
                        component: () => import('@/views/studentViews/recordsAppeal'),
                        meta: {
                            title: '学生扣分申诉',
                            permission: ['studentRebuke']
                        },
                    }, {

                        path: '/unbindApply',
                        name: 'UnbindApply',
                        component: () => import('@/views/studentViews/unbindApply'),
                        meta: {
                            title: '学生注册号解绑申请',
                            permission: ['accountDeleteApply']
                        },
                    }, {

                        path: '/certificateApply',
                        name: 'CertificateApply',
                        component: () => import('@/views/studentViews/certificateApply'),
                        meta: {
                            title: '志愿者证照片审核',
                            permission: ['volunteerPicVerify']
                        },
                    },
                ]
            },
            {
                path: 'dataVisual',
                name: 'DataVisual',
                component: RouteView,
                meta: {
                    title: '数据可视化',
                    permission: ['statistics'],
                    icon: "icon-a-zu19177"
                },
                children: [{
                        path: '/activityData',
                        name: 'ActivityData',
                        component: () => import('@/views/dataVisual/activityData'),
                        meta: {
                            title: '活动数据',
                            permission: ['statisticsActivity']
                        },
                    },
                    {
                        path: '/volunteerData',
                        name: 'VolunteerData',
                        component: () => import('@/views/dataVisual/volunteerData'),
                        meta: {
                            title: '志愿者数据',
                            permission: ['statisticsVolunteer']
                        },
                    },
                ]
            },
            {
                path: 'systemManage',
                name: 'SystemManage',
                component: RouteView,
                meta: {
                    title: '系统管理',
                    permission: ['system'],
                    icon: "icon-a-zu17073"
                },
                children: [{
                        path: '/accountManage',
                        name: 'AccountManage',
                        component: () => import('@/views/systemManage/accountManage'),
                        meta: {
                            title: '账号管理',
                            permission: ['accountManage']
                        },
                    },
                    {
                        path: '/roleManage',
                        name: 'RoleManage',
                        component: () => import('@/views/systemManage/roleManage'),
                        meta: {
                            title: '角色管理',
                            permission: ['roleManage']
                        },
                    },
                    {
                        path: '/noticeManage',
                        name: 'NoticeManage',
                        component: () => import('@/views/systemManage/noticeManage/index'),
                        meta: {
                            title: '公告管理',
                            permission: ['noticeManage'],
                            isAlive:true
                        },
                    },
                    {
                        path: '/noticeManage/detail',
                        name: 'NoticeManageDetail',
                        component: () => import('@/views/systemManage/noticeManage/detail'),
                        meta: {
                            title: '编辑',
                            permission: ['noticeManage'],
                            parentRoute: [{
                                title: "公告管理",
                                path: "/noticeManage"
                            }]
                        },
                        hidden: true
                    },
                    {
                        path: '/miniManage',
                        name: 'MiniManage',
                        component: () => import('@/views/systemManage/miniManage'),
                        meta: {
                            title: '小程序首页图',
                            permission: ['programIndexPic']
                        },
                    },
                    {
                        path: '/logManage',
                        name: 'LogManage',
                        component: () => import('@/views/systemManage/logManage'),
                        meta: {
                            title: '操作日志',
                            permission: ['operateLog']
                        },
                    },
                ]
            },
        ]
    },
    {
        path: '*',
        redirect: '/404'
    }
]
export default asyncRouters
