<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-08-23 16:23:51
 * @LastEditTime: 2021-08-23 17:11:59
-->
<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
  export default {
    name: 'icon-svg',
    props: {
      iconClass: {
        type: String,
        required: true
      }
    },
    computed: {
      iconName() {
        return `#icon-${this.iconClass}`
      }
    }
  }
</script>

<style scoped lang="scss">
  .svg-icon {
    width: 20px;
    height: 20px;
    fill: currentColor;
    // color: red;
    // vertical-align -.2em
    // fill currentColor
    // overflow hidden
  }
</style>