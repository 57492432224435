/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-09 17:59:18
 * @LastEditTime: 2021-06-09 18:08:49
 */
const breadcrumb = {
    state: {
        breadcrumbArr: []
    },
    mutations: {
        SET_BREAD: (state, intArr) => {
            state.breadcrumbArr = intArr
        }
    },
    actions: {
        changeBread({ commit }, intArr) {
            commit('SET_BREAD', intArr)
        }
    }
}
export default breadcrumb
